/* Order List, Vue Component */
<template>

  <v-container>

    <v-card class="entity-view">
      <v-card-title>Book-in Against Order</v-card-title>

      <v-card-text class="d-flex align-center justify-space-between flex-wrap pb-0">

      <div class="fields d-flex align-center flex-wrap pb-0">
      <div>
        <h4>Reference</h4>
        <output>
          <router-link v-if="entity.Id" class="font-weight-medium text-decoration-none"
        :to="{ name: 'order-view', params: { id: entity.Id } }">
        {{ entity.Reference }}
      </router-link></output>
      </div>

      <div>
        <h4>Status</h4>
        <output><order-status-chip v-if="entity.StatusId_Name" :status="entity.StatusId_Name"></order-status-chip></output>
      </div>

      </div>

      <div class="fields d-flex flex-wrap pb-0">

      <div>
        <h4>Member</h4>
        <output>
          <router-link v-if="entity.MemberId" class="font-weight-medium text-decoration-none"
        :to="{ name: 'member-view', params: { id: entity.MemberId } }">
        {{ entity.MemberId_Reference }}
      </router-link></output>
      </div>

      <div>
        <h4>Name</h4>
        <output>{{ entity.MemberId_FullName }}</output>
      </div>

      </div>

      <div class="fields d-flex flex-wrap pb-0">

      <div>
        <h4>Product</h4>
        <output>{{ entity.OrderType }}</output>
      </div>

      <div v-if="entity.ProductId">
        <h4>Product Dims</h4>
        <output>{{ entity.ProductId_Length }}x{{ entity.ProductId_Width }}x{{ entity.ProductId_Height }} mm</output>
      </div>

      <div v-if="entity.CustomLength">
        <h4 v-if="entity.ProductId">Bespoke Dims</h4>
        <h4 v-else-if="entity.QuotePrice">Quote Dims</h4>
        <h4 v-else>Dimensions</h4>
        <output>{{ entity.CustomLength }}x{{ entity.CustomWidth }}x{{ entity.CustomHeight }} mm</output>
      </div>

      <div>
        <h4>Item Description</h4>
        <output>{{ entity.ItemDescription }}</output>
      </div>

      </div>

      <div class="fields d-flex flex-wrap pb-0">

      <div>
        <h4>Retailer</h4>
        <output>{{ entity.RetailerName }}</output>
      </div>

      <div>
        <h4>Reference</h4>
        <output>{{ entity.RetailerReference }}</output>
      </div>

      <div>
        <h4>Tracking Reference</h4>
        <output>{{ entity.RetailerTrackingReference }}</output>
      </div>

      <div>
        <h4>Expected Date</h4>
        <output>{{ Display.DisplayDate(entity.RetailerExpectedDeliveryDate) }}</output>
      </div>

      <div v-if="entity.ExpectMultipleItems">
        <v-chip color="warning v-chip-light-bg" outlined x-small>Expect Multiple Items</v-chip>
      </div>

      </div>

      <div class="my-6 ml-auto mjustify-right">

    <v-btn color="error" x-small
        :to="{ name: 'order-bookin' }">Not This Order</v-btn>
      </div>

      </v-card-text>

    </v-card>

    <v-container v-if="entity && (entity.StatusId_Name == 'Quote' || entity.StatusId_Name == 'Pending' || entity.StatusId_Name == 'Active')" class="pa-0 entity-view">
      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="entity-view mt-6 fill-height d-flex flex-column">
         <v-card-title>Add Item</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">

        <v-form ref="addForm">
      <v-row>
        <v-col>
        <v-text-field
        dense
        outlined
        flat
        label="Length"
        type="number"
        suffix="mm"
      v-model="newItem.Length"
      :rules="[validators.requiredInteger,validators.positiveIntegerValidator,validators.integerRange(newItem.Length, 0, 10000)]"
    ></v-text-field>
</v-col>
<v-col>
        <v-text-field
        dense
        outlined
        flat
        label="Width"
        type="number"
        suffix="mm"
      v-model="newItem.Width"
      :rules="[validators.requiredInteger,validators.positiveIntegerValidator,validators.integerRange(newItem.Length, 0, 10000)]"
    ></v-text-field>
    </v-col>
    <v-col>

<v-text-field
        dense
        outlined
        flat
        label="Height"
        type="number"
        suffix="mm"
      v-model="newItem.Height"
      :rules="[validators.requiredInteger,validators.positiveIntegerValidator,validators.integerRange(newItem.Length, 0, 10000)]"
    ></v-text-field>
</v-col>
</v-row>
<v-row>
  <v-col>
        <v-text-field
        hide-details
        dense
        outlined
        flat
        label="Notes"
      v-model="newItem.Notes"
      :rules="[]"
    ></v-text-field>
    </v-col>
</v-row>
<v-row>
<v-col>
  <v-checkbox label="Heavy" hide-details v-model="newItem.Heavy" :color="(newItem.Heavy ? 'info' : 'secondary')"></v-checkbox>
</v-col>
<v-col>
  <v-checkbox label="DGN" hide-details v-model="newItem.Dangerous" :color="(newItem.Dangerous ? 'warning' : 'secondary')"></v-checkbox>
</v-col>
<v-col>
  <v-checkbox label="Damaged" hide-details v-model="newItem.Damaged" :color="(newItem.Damaged ? 'error' : 'secondary')"></v-checkbox>
</v-col>
<v-col>
  <v-checkbox label="Refused Delivery" hide-details v-model="newItem.RefusedDelivery" :color="(newItem.RefusedDelivery ? 'error' : 'secondary')"></v-checkbox>
</v-col>
<v-col>
  <v-checkbox label="Do Not Send" hide-details v-model="newItem.DoNotSend" :color="(newItem.DoNotSend ? 'error' : 'secondary')"></v-checkbox>
</v-col>
</v-row>
<v-row class="mt-auto d-flex align-center pt-6">
      <v-col>
        <v-btn color="primary" @click="addItem">Add Item</v-btn>
      </v-col>
      <v-col class="guesstimate" v-if="AllowGuesstimate">
        <!-- <v-btn color="secondary" @click="addGuesstimatedItem">Add With Product Dimensions</v-btn> -->
        <v-overflow-btn segmented :value="guesstimateItemsIndex" :items="guesstimateItems" label="Guesstimate" ></v-overflow-btn>
      </v-col>
</v-row>
      </v-form>

      </v-card-text>

    </v-card>

      </v-col>

      <v-col cols="12" sm="6">
        <v-card class="entity-view mt-6 fill-height d-flex flex-column">
      <v-card-title>Item(s)</v-card-title>

        <v-card-text v-if="items && items.length > 0" class="d-flex align-center justify-space-between flex-wrap pb-0">

          <div class="fields d-flex align-center flex-wrap pb-0" v-for="(item, index) in items" :key="index">
            <div class="mb-2">
              <h4>Length</h4>
              <output>{{ item.Length }}mm</output>
            </div>
            <div class="mb-2">
              <h4>Width</h4>
              <output>{{ item.Width }}mm</output>
            </div>
            <div class="mb-2">
              <h4>Height</h4>
              <output>{{ item.Height }}mm</output>
            </div>
            <div class="mb-2" v-if="item.Heavy">
              <h4>Heavy</h4>
              <output>YES</output>
            </div>
            <div class="mb-2" v-if="item.Dangerous">
              <h4>DGN</h4>
              <output>YES</output>
            </div>
            <div class="mb-2" v-if="item.Damaged">
              <h4>Damaged</h4>
              <output>YES</output>
            </div>
            <div class="mb-2" v-if="item.RefusedDelivery">
              <h4>Refused Delivery</h4>
              <output>YES</output>
            </div>
            <div class="mb-2" v-if="item.DoNotSend">
              <h4>Do Not Send</h4>
              <output>YES</output>
            </div>
            <div class="mb-2" v-if="item.Guesstimated">
              <h4>Guesstimated</h4>
              <output>YES</output>
            </div>
            <div class="mb-2" v-if="item.Notes">
              <h4>Notes</h4>
              <output>{{ item.Notes }}</output>
            </div>
            <div class="mb-2">
              <v-btn x-small text color="error" @click="deleteItem(item)">Remove</v-btn>
            </div>
          </div>

      </v-card-text>

      <v-card-text v-if="items && items.length > 0" class="mt-auto d-flex align-center">
        <v-btn color="success" @click="bookItems">Book Items</v-btn>
        <div class="pa-3">Are there any more items to be added to this order?</div>
      </v-card-text>
  </v-card>

    </v-col>
    </v-row>
    </v-container>

    <v-card v-else-if="entity.StatusId_Name == 'Quote Pending'" class="mt-6">
      <v-card-text>
        Order Status '{{ entity.StatusId_Name }}' prevents items from being booked.
      </v-card-text>
      <v-card-actions>
        <v-btn :to="{ name: 'order-quote', params: { id: entity.Id } }" color="success">Create a quote</v-btn>
      </v-card-actions>
    </v-card>

    <v-chip v-else color="error" large class="my-6 mx-3">Order Status '{{ entity.StatusId_Name }}' prevents items from being booked.</v-chip>


  </v-container>

</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  components: {
    OrderStatusChip
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.Order);
    const newItem = ref({});
    const loading = ref(false);

    const items = ref([]);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchOrder', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'order-bookin'});
        })
    }


    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }

    const deleteItem = deleteItem => {
      let deleteIndex = items.value.indexOf(deleteItem);
      items.value.splice(deleteIndex - 1, 1);
    }

    const addForm = ref(null);
    const addItem = () => {
      if (addForm.value.validate()) {
        if (!newItem.value.Notes) delete newItem.value['Notes'];
        if (!newItem.value.Heavy) delete newItem.value['Heavy'];
        if (!newItem.value.Dangerous) delete newItem.value['Dangerous'];
        if (!newItem.value.Damaged) delete newItem.value['Damaged'];
        if (!newItem.value.RefusedDelivery) delete newItem.value['RefusedDelivery'];
        if (!newItem.value.DoNotSend) delete newItem.value['DoNotSend'];
        newItem.value.Guesstimated = false;
        items.value.push(Object.assign({}, newItem.value));
        newItem.value = {};
        addForm.value.reset();
      }
    }

    const addGuesstimatedItem = (n) => {

      let totalLength = 1;
      let dividedLength = 1;
      if (entity.value.ProductId_Length) {
        totalLength = entity.value.ProductId_Length;
        dividedLength = Math.floor(totalLength / n);
      } else if (entity.value.CustomLength) {
        totalLength = entity.value.CustomLength;
        dividedLength = Math.floor(totalLength / n);
      }

      let width = 1;
      if (entity.value.ProductId_Width) {
        width = entity.value.ProductId_Width;
      } else if (entity.value.CustomWidth) {
        width = entity.value.CustomWidth;
      }

      let height = 1;
      if (entity.value.ProductId_Height) {
        height = entity.value.ProductId_Height;
      } else if (entity.value.CustomHeight) {
        height = entity.value.CustomHeight;
      }

      for (let i = 1; i <= n; i++) {

        newItem.value.Length = dividedLength; //entity.value.ProductId_Length;
        newItem.value.Width = width;
        newItem.value.Height = height;

        //if (addForm.value.validate()) {
          if (!newItem.value.Notes) delete newItem.value['Notes'];
          if (!newItem.value.Dangerous) delete newItem.value['Dangerous'];
          if (!newItem.value.Heavy) delete newItem.value['Heavy'];
          if (!newItem.value.Damaged) delete newItem.value['Damaged'];
          if (!newItem.value.RefusedDelivery) delete newItem.value['RefusedDelivery'];
          if (!newItem.value.DoNotSend) delete newItem.value['DoNotSend'];
          newItem.value.Guesstimated = true;
          items.value.push(Object.assign({}, newItem.value));
          newItem.value = {};
          addForm.value.reset();
        //}
      }
    }

    const guesstimateItemsIndex = ref(1);
    const guesstimateItems = ref([]);
    for (let i = 1; i < 11; i++) {
      guesstimateItems.value.push({
        value: i,
        text: `Guesstimate ${i} item${i > 1 ? 's' : ''}`,
        callback: function(e) {
          addGuesstimatedItem(i);
          guesstimateItemsIndex.value = i;
          setTimeout(() => {
            guesstimateItemsIndex.value = i;
          }, 300);
        }
      });
    }

    const GuesstimateForBespoke = ref("");
  const fetchGuesstimateForBespoke = () => {
    store
      .dispatch('app/fetchSettings', {
        filter: {
          Category: "Items"
        },
      })
       .then(response => {
         if (response.data && response.data.items && response.data.items.length > 0) {
          let guesstimateSetting = response.data.items.find(setting => setting.Name == "GuesstimateForBespoke");
          if (guesstimateSetting) {
            GuesstimateForBespoke.value = guesstimateSetting.Value;
          }
         }
       })
      .catch(error => {
        console.log(error)
      })
  }
  fetchGuesstimateForBespoke();

  const AllowGuesstimate = computed (() => {
    return (entity && (
                        (entity.value.ProductId && entity.value.ProductId_Guesstimate) ||
                        (entity.value.ProductId == null && ["YES", "TRUE"].includes(GuesstimateForBespoke.value.toUpperCase()))
    ));
  });

  const bookItems = () => {

    loading.value = true;

    store
        .dispatch('app/bookItemsOrder', {
          id: route.value.params.id,
          entity: entity.value,
          items: items.value
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'order-print', params: { id: response.data.Id } });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
  }

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    return {
      AllowGuesstimate,
      guesstimateItemsIndex,
      guesstimateItems,
      bookItems,
      addForm,
      loading,
      entity,
      newItem,
      Display,
      booleanIcons,
      items,
      deleteItem,
      addItem,
      validators,
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose
      },
    };
  }
}
</script>

<style lang="scss" scoped>
    ::v-deep .guesstimate {
      min-width: 250px;

      .v-input__slot {
        border-left: 1px solid rgba(94, 86, 105, 0.14);
        border-right: 1px solid rgba(94, 86, 105, 0.14);
      }

      .v-select__selections {
        height: 48px;
         .v-btn {
          min-height: 48px;
         }
      }
    }
</style>
